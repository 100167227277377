<template>
  <v-card>
    <v-card-text>
      <h6 class="mt-3">{{ $t('system.total') }}: {{ total || '0.00' }}</h6>
      <v-data-table
        :headers="headers.map(header => ({...header, text: $t(header.text)}))"
        :items="positions"
        :loading="loading"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import notification from "@/notification/notify";

export default {
  name: 'GiftsStep',
  props: {
    visitId: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    headers: [
      {
        text: 'system.name',
        sortable: true,
        value: 'name',

      },
      {
        text: 'system.quantity',
        sortable: true,
        value: 'quantity',
      }
    ],
    loading: false,
    positions: [],
    total: null
  }),
  beforeMount() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const {data} = await axios.get(`sk_visits/${this.visitId}/step/gifts`)
        this.positions = data.positions.map(({gift, quantity}) => ({name: gift.name, quantity}))
        this.total = data.total
      } catch (e) {
        notification.notify(
          this.$t('notify.error'),
          this.$t('system.can_not_load'),
          'error');
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
